#feedback-dialog-trigger {
  position: fixed;
  bottom: 120px;
  right: 20px;
}

#feedback-dialog {
  .rating {
    .mdc-form-field {
      display: inline-block;

      .mdc-radio {
        display: none;
      }
    }
  }
}

.contact-us-form {
  max-width: none;
}

@media (min-width: 960px) {
  #feedback-dialog.mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    width: 532px;
  }
}
