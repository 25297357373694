.lists-projects {
  h1 {
    display: flex;

    .mdc-touch-target-wrapper {
      margin-left: 16px;
    }
  }
}

.lists-project-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  .mdc-list-item__text {
    font-size: 1.5rem;
    font-weight: bold;
  }

  button {
    margin: 0;
  }
}
