@use '@material/card';

.filter_irlms#action-show {
  .filtered-irlms {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    .filter-card-title {
      padding: 0.5rem;
      font-weight: bold;
      text-align: center;
      width: 275px;
      height: 5.5rem;
      position: relative;
    }

    .mdc-card.selected {
      @include card.outline(black, 3px);
    }

    .mdc-card__primary-action {
      img {
        width: 275px;
        height: 150px;
        padding-bottom: 3.25rem;
      }
    }

    .mdc-card__actions {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }
  }
}
