@use '../_colors.scss';

.lists-strategies {
  .mdc-list-item__primary-text {
    text-wrap: wrap;
  }

  .tall-select {
    #select-selected-text {
      text-wrap: wrap;
    }

    .mdc-select__selected-text-container {
      height: auto;
    }

    .mdc-select--filled .mdc-select__anchor {
      min-height: 56px;
      height: fit-content;
    }
  }

  .strategies-table-container {
    display: flex;
    flex-direction: column;

    .toggle-strategies {
      margin-bottom: 32px;
    }

    .fa-square-check {
      color: green;
    }
  }

  .fa-circle-exclamation {
    color: orange;
  }
}

.lists-strategies#action-show {
  #body-content {
    max-width: 100%;

    .strategy-info-page {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .strategy-info {
        margin-right: 3rem;
      }

      .strategy-info-buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        a,
        button {
          width: 20rem;
          margin-top: 0.5rem;
        }
      }
    }
  }
}

.lists-strategies#action-new {
  input::-webkit-datetime-edit-day-field,
  input::-webkit-datetime-edit-month-field,
  input::-webkit-datetime-edit-year-field {
    opacity: var(--field-opacity, 0);
  }

  input[type='date'].has-value {
    --field-opacity: 1;
  }
}
