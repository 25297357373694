.operationalizations {
  .title {
    width: 250px;
    white-space: break-spaces;
  }

  .form-cell {
    padding: 0;
  }

  form {
    display: flex;
    max-width: 100%;

    label {
      flex: 1;
    }

    .action {
      width: 150px;
      text-align: center;
      padding-top: 4px;
    }
  }
}
