.team-memberships form {
  max-width: max-content;

  .team-member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .mdc-touch-target-wrapper {
      margin-left: 1rem;
      margin-bottom: 0.75rem;
    }
  }
}
