.logic_models#action-show {
  .show-irlm-page {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    .irlm-export-div {
      width: 100%;

      .irlm {
        display: flex;
        flex-direction: row;
        align-items: center;

        .irlm-pic {
          margin-right: 1rem;
        }

        a {
          height: auto;
          margin-top: 7rem;
        }

        li,
        p {
          text-align: left;
        }
      }
    }

    .irlm-options {
      margin-top: 1rem;

      .irlm-buttons {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .mdc-button {
          margin: 0.35rem;
        }
      }
    }
  }
}
