.home#action-index {
  h1,
  h2 {
    text-align: center;
  }

  #logos {
    display: flex;
    justify-content: space-evenly;
  }

  .actions {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 480px;
    margin: 0 auto;

    .mdc-button__label {
      font-size: 1rem;
    }

    .mdc-touch-target-wrapper {
      margin-bottom: 44px;
      text-align: center;

      .mdc-button {
        height: 80px;
        width: 280px;
      }
    }
  }
}
