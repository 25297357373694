@use '../_colors.scss';
@use '@material/elevation';
@use '@material/elevation/mdc-elevation';

.lists-projects#action-dashboard {
  h1,
  h2 {
    margin-left: 32px;
  }

  .mdc-top-app-bar--fixed-adjust {
    padding-bottom: 64px;
  }

  .left-button {
    margin-left: 32px;
  }

  #export-button {
    position: absolute;
    top: 0;
    right: 32px;
  }

  #body-content {
    max-width: unset;
    padding: 0;
  }

  #dashboard-timeline-wrapper {
    .dashboard-screenshot-target {
      position: absolute;

      .strategies-table {
        display: table;
        table-layout: fixed;
        width: calc(100% - 120px);
        margin-left: 100px;

        .bundle-row,
        .strategy-row {
          display: table-row;
          height: 40px;

          .day-strategy {
            position: relative;
            display: table-cell;
          }

          .strategy {
            position: absolute;
            left: 0;
            z-index: 1;
            text-align: center;
            font-weight: 600;
            white-space: nowrap;

            .strategy-bar {
              border-radius: 5px;
              height: 20px;
            }

            &:hover .strategy-bar {
              @include elevation.elevation(2);
            }

            &.strategy-active > .strategy-bar {
              background-color: colors.$secondary;
            }

            &.strategy-discontinued > .strategy-bar {
              background-color: colors.$inactive !important;
            }

            &.strategy-modified > .strategy-bar {
              background-color: colors.$modified;
            }

            .strategy-label {
              color: colors.$label;
              margin-left: -200px;
              margin-right: -200px;
              text-align: center;
              line-height: 1.25rem;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .bundle-row {
          .bundle {
            background-color: #d7d7d7;
            border-radius: 10px;
            height: calc(100% - 5px);
            display: inline-table;
            margin-bottom: 8px;
            margin-left: -1px;

            .title {
              color: #418fc2;
              display: block;
              font-weight: 600;
              margin-left: -200px;
              margin-right: -200px;
              text-align: center;
              position: relative;
              z-index: 1;
            }
          }
        }

        .timeline-arrow-row {
          display: table-row;
          position: relative;
          height: 24px;

          .arrow {
            display: flex;
            align-items: center;
            width: 100%;

            .arrow-line {
              width: 100%;
              height: 6px;
              background-color: colors.$secondary;
            }

            .arrow-icon {
              font-size: 24px;
            }
          }
        }

        .x-label-row {
          display: table-row;

          .x-label-day {
            position: relative;
            display: table-cell;
            height: 1em;

            &.dated {
              border-left: 1px dashed #aaa;
            }

            .timeline-bar {
              position: absolute;
              bottom: 27px;
              width: 3px;
              background-color: #bbb;
              z-index: -1;
            }

            .x-label {
              position: absolute;
              width: 140px;
              right: -21px;
              text-align: right;
              transform: rotate(-45deg);
              margin: 0;
              font-size: 14px;
              top: 44px;
            }

            .x-date {
              position: absolute;
              width: 176px;
              left: -88px;
              text-align: center;
              margin: 0;
              top: 48px;
            }
          }
        }
      }
      .dashboard-color-legend {
        position: relative;
        display: flex;
        align-items: baseline;
        justify-content: center;
        height: 4rem;
        margin-top: 64px;

        .legend-box {
          font-weight: bolder;
          color: white;
          width: 8rem;
          text-align: center;
          padding: 0.5rem 0;
          border: 1.5px solid black;
          border-radius: 5px;
          margin: 2rem 0.5rem;

          &.strategy-active {
            background-color: colors.$secondary;
          }

          &.strategy-discontinued {
            background-color: colors.$inactive !important;
          }

          &.strategy-modified {
            background-color: colors.$modified;
            color: black;
          }
        }
      }
    }
  }
}
