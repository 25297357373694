.connections#action-index {
  .mdc-tab-bar {
    margin-bottom: 16px;
  }

  .mdc-data-table__cell:nth-child(2),
  .mdc-data-table__cell:nth-child(3),
  .mdc-data-table__cell:nth-child(4),
  .mdc-data-table__cell:nth-child(5),
  .mdc-data-table__cell:nth-child(6),
  .mdc-data-table__cell:nth-child(7) {
    white-space: initial;
  }
}
