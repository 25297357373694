.structure_confirmations#action-show {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  h2,
  .info {
    text-align: center;
  }

  .irlm-options {
    margin-top: 1rem;

    .irlm-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .mdc-button {
        margin: 0.35rem;
      }
    }
  }
}
