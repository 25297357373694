.nav-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 0;
  max-width: 855px;

  @media (max-width: 1024px) {
    max-width: 90%;
  }

  @media (max-width: 480px) {
    max-width: 85%;
  }
}
