$min-total-height: 400px;
$gutter-width: 24px;
$column-width: 194px;
$border-width: 1px;
$vertical-arrow-height: 25px;
$arrow-row-padding-top: 3px;
$label-height: 48px;
$label-vertical-padding: 4px;

.show-irlm-title {
  border-bottom: 1px solid black;
  font-weight: 300;
  padding-bottom: 0.25rem;
  width: fit-content;
  margin: 1rem auto;
}

.irlm-structure {
  min-height: $min-total-height;

  &.table-layout {
    display: table;
  }

  .irlm-vertical-box {
    display: table-cell;
    border: $border-width solid black;
    width: $column-width;
    text-align: left;
    vertical-align: top;

    .domain,
    .innovation,
    .innovation-mechanism {
      margin: 0 8px;
    }

    .box-content {
      min-height: calc(
        (
            $min-total-height - $vertical-arrow-height - $arrow-row-padding-top -
              2 * ($label-height + 2 * $label-vertical-padding)
          ) / 2
      );
    }

    ul {
      padding-left: 24px;

      li {
        font-size: 15px;
      }
    }
  }

  .irlm-outer-row {
    display: table-row;
  }

  .irlm-arrow-column {
    display: table-cell;
    width: $gutter-width;
    vertical-align: middle;
    text-align: center;

    & * {
      margin: 32px 0;
    }
  }

  .irlm-arrow-row {
    display: table-cell;
    text-align: center;
    height: $vertical-arrow-height;
    padding-top: $arrow-row-padding-top;
  }

  .irlm-cell {
    display: table-cell;
    min-width: $gutter-width;
  }

  .colspan2,
  .colspan2 label {
    width: 2 * $column-width + $gutter-width + 2 * $border-width;
  }

  .color1 {
    margin: 4px;
    border: 1px solid #999;
    background-color: rgb(210, 220, 240);
    min-height: 3em;
  }

  .color2 {
    margin: 4px;
    border: 1px solid #999;
    background-color: rgb(154, 173, 209);
    min-height: 3em;
  }

  .color3 {
    margin: 4px;
    border: 1px solid #999;
    background-color: rgb(35, 62, 126);
    min-height: 3em;

    .strategy {
      color: white;
    }
  }

  .color4 {
    margin: 4px;
    border: 1px solid #999;
    background-color: rgb(25, 41, 81);
    min-height: 3em;

    .strategy {
      color: white;
    }
  }

  .color5 {
    background-color: rgb(233, 233, 233);
  }

  .color6 {
    background-color: rgb(249, 223, 205);
  }

  label {
    background-color: gray;
    color: white;
    display: table-cell;
    text-align: center;
    padding: $label-vertical-padding 0;
    height: $label-height;
    width: $column-width;
    vertical-align: middle;
  }
}
