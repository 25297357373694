.lists#body-content {
  min-height: unset;
}

.text-field-with-tooltip {
  margin-top: 24px;
  position: relative;

  .mdc-tooltip-wrapper--rich {
    position: absolute;
    right: -44px;
    top: 10px;
  }

  .mdc-tooltip__surface .mdc-tooltip__content a {
    font-size: 0.875rem;
  }
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 4;

  p {
    max-width: 855px;
    margin: auto;
    text-align: center;
  }
}
