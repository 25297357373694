@use '../_colors.scss';

.lists-homes#action-show {
  h2 {
    text-align: center;

    &:last-child {
      margin-bottom: 80px;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    text-align: center;

    .action-with-tooltip {
      position: relative;
    }

    .mdc-button__label {
      font-size: 1rem;
    }

    .mdc-touch-target-wrapper {
      margin-bottom: 44px;

      .mdc-button {
        height: 80px;
        width: 280px;
      }
    }

    .mdc-tooltip-wrapper--rich {
      display: inline;
      position: absolute;
      left: calc(50% + 148px);
      top: calc(50% - 18px);

      .mdc-button {
        height: 36px;
        width: unset;
      }

      .tooltip-orange-text {
        color: colors.$highlighted;
        font-size: inherit;
        line-height: inherit;
      }
    }
  }
}
